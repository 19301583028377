* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  /* margin: 0;
  padding: 1rem;
  font-family: system-ui, sans-serif;
  color: black;
  background-color: white; */
}

h1 {
  /* font-weight: 800;
  font-size: 1.5rem; */
}

.tubes-container::before {
  content: '';
  flex-grow: 1;
}

.tubes-container::after {
  content: '';
  flex-grow: 4;
}
